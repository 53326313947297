<template>
  <helper-input-btn>
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        :size="size"
        @keydown.enter="search"
        readonly
    >
      <u-popover v-if="this.findedData" hover :hover-delay="500" class="popover-user-profile" anchor="bottom middle"
                 self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          <div class="row">
            <div class="col m-r">ID: <strong>{{findedData.id}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Nome: <strong>{{findedData.name}}</strong></div>
          </div>
          <div class="m-t">
            <e-btn @click="gotoPerson(findedData)" label="Acessar pessoa" />
          </div>
        </div>
      </u-popover>
    </erp-input>
    <div v-if="!disable" class="erp-icon-float"><a @click="search"><i class="erp-icon search min"></i> </a></div>
<!--    <img v-if="findedData && findedData.photo" :src="findedData.photo" style="height: 20px" />-->
  </helper-input-btn>
</template>

<script>
import InputMixin from '@/reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import search from '@/components/pessoa/components/include/search/SearchPessoa'
import GlobalPersonMixin from "components/pessoa/globalPersonMixin"

export default {
  name: 'PessoaInputV2',
  props: ['size'],
  mixins: [InputMixin, GlobalPersonMixin],
  data () {
    return {
      findedData: this.value
    }
  },
  watch: {
    findedData (v) {
      this.$emit('updateSelected', v)
    },
    findedDataObj (v) {
      this.adjust()
    }
  },
  mounted () {
    this.adjust()
  },
  components: {HelperInputBtn, UPopover},
  methods: {
    adjust () {
      if (this.findedData) {
        this.description = this.findedData.id + ' - ' + this.findedData.name
      }
    },
    __updateInternal () {
      this.updateInternal((v) => v.id + ' - ' + v.name)
    },
    search: search,
    gotoPerson (p, target = '_blank') {
      window.open('/#/pessoas/' + p.id, target)
    }
  }
}
</script>
