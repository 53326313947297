<template>
  <helper-input-btn :default-slot-css="'flex flex-center no-wrap'">
    <erp-input
        ref="input"
        @input="__set"
        slot="input"
        simple-border
        v-model="description"
        @keydown.enter="search"
        readonly
    >
      <u-popover v-if="findedData" hover :hover-delay="1000" class="popover-user-profile" anchor="bottom middle"
                 self="top middle" :offset="[10, 10]">
        <div class="wrapper bg-white font-12">
          <div class="row">
            <div class="col m-r">ID do bem: <strong>{{findedData.id}}</strong></div>
            <div class="col bold">Código: <strong>{{findedData.code}}</strong></div>
          </div>
          <div class="row m-t">
            <div class="col m-r">Nome: <strong>{{findedData.name}}</strong></div>
          </div>
          <div class="m-t">
            <div class="m-b">Descrição</div>
            <div><strong>{{findedData.description}}</strong></div>
          </div><!--
          <div class="m-t">
            <e-btn @click="documentoWindow(findedData.id)" label="Acessar Documento" />
          </div>-->
        </div>
      </u-popover>
    </erp-input>
    <div class="erp-icon-float" style="z-index: 4; position: relative"><a @click="search"><i class="erp-icon search min"></i> </a></div>
  </helper-input-btn>
</template>

<script>
import InputMixin from '../../../../reuse/input/mixin'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import search from '@/components/forms/components/include/SearchForm'
// import documentoWindow from "@/components/documentos/components/window/documento"
import {findForm} from "@/domain/form/services"

export default {
  name: 'FormInput',
  mixins: [InputMixin],
  props: {
    finalidade: null
  },
  data () {
    return {
      findedData: null,
      description: null
    }
  },
  mounted() {
    console.log('Documento: ', this.value)
    if (this.value) {
      findDocumento(this.value)
          .then(response => {
            /*this.findedData = {
              label: response.data.nome,
              value: response.data.id,
              ...response.data
            }*/
            this.description = `${response.data.id} - ${response.data.name} [${response.data.code}]`
          })
          .catch(error => {
            console.error(error)
          })
    }
  },
  watch: {
  },
  components: {HelperInputBtn, UPopover},
  methods: {
    setData (d) {
      this.findedData = d
    },
    __updateInternal() {
      this.updateInternal((v) => v.id + ' - ' + v.name)
      this.$emit('changeDocumento', this.findedData)
    },
    search: search,
    // documentoWindow: documentoWindow
  }
}
</script>
