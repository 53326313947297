import http from '@/utils/services/http'

export const registerFormResponse = (form, data) => {
    let url = '/api/forms/register'
    let method = http.post
    if (data.id) {
        method = http.patch
    }
    return method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const mountForm = (id = null, code = null) => {
    let url = '/api/forms/mount'
    if (code) {
        url = url + '?code=' + code
    } else {
        url = url + '?id=' + id
    }
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const getForm = (formId = null, entityName = null, entityId = null, code = null, responseEntityId = null) => {
    let url = '/api/forms/mount'
    const props = []
    formId && props.push('id=' + formId)
    entityName && props.push('entityName=' + entityName)
    entityId && props.push('entityId=' + entityId)
    code && props.push('code=' + code)
    responseEntityId && props.push('responseEntityId=' + responseEntityId)
    url = url + '?' + props.join('&')
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const listForm = (limit, page, filtros) => {
    let url = '/api/forms?page=' + page + '&limit=' + limit + filtros
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findForm = (id) => {
    let url = `/api/forms/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newForm = (data) => {
    let url = `/api/forms`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateForm = (data, method = 'PUT') => {
    let url = `/api/forms/${data.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteForm = (id) => {
    let url = `/api/forms/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const saveForm = (data) => {
    let url = `/api/forms/save`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
